import { Link } from "gatsby";
import React from "react";

import "./demo-button-container.scss"

let demoTitle = '';
let demoSubtitle = '';
let demoButtonText = '';
let meetButtonText = '';

class DemoButtonContainer extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        if (this.props.titles != null) {
            for (const title of this.props.titles) {
                if (this.props.url != null && (this.props.url === 'demo' || this.props.url === 'visual-tour-builder')) {
                    if (title.indicator === 'demo-conversion-title') {
                        demoTitle = title.value;
                    }
                    if (title.indicator === 'demo-conversion-description') {
                        demoSubtitle = title;
                    }
                    if (title.indicator === 'demo-conversion-demo-button') {
                        demoButtonText = title.value;
                    }
                    if (title.indicator === 'demo-conversion-meet-button') {
                        meetButtonText = title.value;
                    }
                } else {
                    if (title.indicator === 'demo-container-title') {
                        demoTitle = title.value;
                    }
                    if (title.indicator === 'demo-container-subtitle') {
                        demoSubtitle = title;
                    }
                    if (title.indicator === 'demo-container-demo-button') {
                        demoButtonText = title.value;
                    }
                    if (title.indicator === 'demo-container-meet-button') {
                        meetButtonText = title.value;
                    }
                }
            }
        }
        this.setState({
            demoTitle,
            demoSubtitle,
            demoButtonText,
            meetButtonText
        });
    }

    render() {
        return <span className="demo-button-component-container">
            <h2>{demoTitle}</h2>
            <p>{demoSubtitle.value}</p>
            {(demoSubtitle.richValue && demoSubtitle.richValue.html) &&
                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: demoSubtitle.richValue.html }} />
            }
            <div>
                <ul className="button-menu">
                    {(this.props.url == null || this.props.url !== 'demo') &&
                        <li className="demo-button-container">
                            <button className="demo-button">
                                <Link id="demo-container-button" key="demo-button" to={`/demo-terugkijken`}>Demo's terugkijken</Link>
                            </button>
                            <p>{demoButtonText}</p>
                        </li>
                    }
                    <li className="more-about-container">
                        <button id="meet-container-button" className="more-about-button">
                            <Link key="meet-button" to={`/demo-afspraak`}>Plan demo in</Link>
                        </button>
                        <p>{meetButtonText}</p>
                    </li>
                </ul>
            </div>
        </span>;
    }
}

export default DemoButtonContainer;
