import { graphql, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { InlineWidget } from "react-calendly"
import ReactPlayer from 'react-player'
import { isSafari } from 'react-device-detect';

import SEO from "../components/seo"
import Layout from "../components/layout"
import DemoButtonContainer from "../components/demo-button-container";
import linkedin from '../assets/icons/linkedin.png';

import "./contentPage.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

const ContentPage = (queryData) => {
    let title = '';
    let description = '';
    let pageContent = '';
    let slug = '';
    let crawlable = true;
    let previousDemoText = '';
    let personalDemoText = '';
    let personalStartDemoText = '';
    let nieuwsbriefTitle = '';
    let nieuwsbriefSubtitle = '';
    let linkedinTitle = '';
    let linkedinText = '';

    let demoTitle = '';
    let demoSubtitle = '';

    const contentPage = queryData.data.gcms.contentPage;
    const titles = queryData.data.gcms.titles;
    const edges = queryData.data.allDataJson.edges;
    // const webinars = queryData.data.allDemioData.edges;
    for (const title of titles) {
        if (title.indicator === 'previous-demos-text' && title.richValue && title.richValue.html) {
            previousDemoText = nl2br(title.richValue.html);
        }
        if (title.indicator === 'personal-demo-text' && title.richValue && title.richValue.html) {
            personalDemoText = nl2br(title.richValue.html);
        }
        if (title.indicator === 'personal-start-demo-text' && title.richValue && title.richValue.html) {
            personalStartDemoText = nl2br(title.richValue.html);
        }
        if (title.indicator === 'new-demo-title') {
            demoTitle = title;
        }
        if (title.indicator === 'new-demo-subtitle') {
            demoSubtitle = title;
        }
        if (title.indicator === 'nieuwsbrief-title' && title.value) {
            nieuwsbriefTitle = title.value;
        }
        if (title.indicator === 'nieuwsbrief-subtitle' && title.value) {
            nieuwsbriefSubtitle = title.value;
        }
        if (title.indicator === 'linkedin-title' && title.value) {
            linkedinTitle = title.value;
        }
        if (title.indicator === 'linkedin-text' && title.richValue && title.richValue.html) {
            linkedinText = nl2br(title.richValue.html);
        }
    }

    const [urlParam, setUrlParam] = useState("");
    const [urlData, setUrlData] = useState(null);

    if (contentPage != null) {
        if (contentPage.pageTitle != null) {
            title = contentPage.pageTitle;
        }
        if (contentPage.pageMetaDescription != null) {
            description = contentPage.pageMetaDescription;
        }
        if (contentPage.pageContent != null && contentPage.pageContent.html != null) {
            pageContent = nl2br(contentPage.pageContent.html);
        }
        if (contentPage.slug != null) {
            slug = contentPage.slug;
        }
        if (contentPage.crawlable != null) {
            crawlable = contentPage.crawlable;
        }
    }

    const [content, setContent] = useState(pageContent);


    const vlogs = edges[0].node.vlogs;

    useEffect(() => {
        const getData = async () => {
            const res = await fetch('https://api64.ipify.org/?format=json');
            const data = await res.json();
            console.log(data)
            setContent(content.replace('(Disable ad blocker to see IP)', data.ip));
        }

        setUrlParam(window.location.pathname.replace(/\//g, ''));

        if (urlParam === 'support') {
            setUrlData(
                <span>
                    <iframe title="Google support calendar" src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0Enn--oUr9D-jXSGYgIB9uqXn5HCiDoarA17s_dypMEO-Dku4X1hbUgFheyDADnpiuq3ifLdrC" style={{border: 0}} width="100%" height="900"></iframe>
                    <InlineWidget
                        color="#f8efef"
                        text="Support Accounting"
                        textColor="#282a2c"
                        primaryColor="#097fd2"
                        url="https://calendly.com/atotz-marcel/support-accounting?hide_gdpr_banner=1"
                        styles={{
                            height: '700px'
                        }}
                    />
                </span>
            );
        } else if (urlParam === 'vlog') {
            setUrlData(
                <div className="vlog-video-container">
                    <h1>Sneak previews</h1>
                    <hr></hr>
                    {vlogs.map(vlog => {
                        return <span key={vlog.title}>
                            <h2>{vlog.title}</h2>
                            <ReactPlayer className="video" url={vlog.url} controls={true} />
                        </span>
                    })}
                </div>
            );
        } else if (urlParam === 'demo-afspraak' || urlParam === 'meet') {
            setUrlData(
                <iframe title="Google support calendar" src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2VdSXWfcjFWiLnCbgsnm-p1sFFC8mCGST7AyvaxXDumK_knRYXOk40BpXAp6rYQQ0RTF0jorhU?gv=true" style={{border: 0}} width="100%" height="900"></iframe>
            );
        } else if (urlParam === 'nextgen-travel-scan') {
            setUrlData(
                <InlineWidget
                    color="#f8efef"
                    text="Request a Demo"
                    textColor="#282a2c"
                    primaryColor="#097fd2"
                    url="https://calendly.com/travelspirit-michiel/next-gen-travel-scan?hide_gdpr_banner=1"
                    styles={{
                        height: '700px'
                    }}
                />
            );
        } else if (urlParam === 'start-demo') {
            setUrlData(
                <iframe title="Google support calendar" src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1xzrRtJmv-4etMbNgLktRs5QjUQ9dFA_O3-QmOAdAnDetbBAbq-XGH0UEwV47xkJLn3X6_3WnJ?gv=true" style={{border: 0}} width="100%" height="900"></iframe>
            );
        } else if (urlParam === '403') {
            if (content.indexOf('(Disable ad blocker to see IP)') > -1) {
                getData();
            }
        }
    }, [urlParam, vlogs, content]);

    return (
        <Layout>
            <SEO title={title} description={description} urlParams={slug} page='' crawlable={crawlable} />
            <img className="print print-image" src={isSafari === true ? 'https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' : 'https://media.travelspirit.nl/original/smw/TravelSpirit_Logo-FC.webp'} alt="logo" loading="lazy" />
            <span className="content-page-container">
                <div className={"page-" + urlParam + " content-container container"}>
                    <div className="inner-container">
                        {urlParam !== 'vtb-zra' && <span>
                            <div className="html-text" dangerouslySetInnerHTML={{ __html: content }} />
                        </span>
                        }
                        {urlParam === 'vtb-zra' && <span>
                            <div className="vtb-text"dangerouslySetInnerHTML={{ __html: content }} />
                        </span>
                        }
                        <span className={urlParam + " url"}>
                            {urlData}
                        </span>
                        {urlParam === 'demo' && <div className="container">
                            {/* {webinars.map((webinar) => {
                                return <Webinair key={webinar.node.name} data={webinar.node} type="demio" />
                            })} */}
                            <span className="row previous-demo-overlapping-container">
                                <div className="col-sm-3 previous-demo-container">
                                    <div className="html-text" dangerouslySetInnerHTML={{ __html: personalDemoText }} />
                                    <Link className="personal-demo-button" key="demo-button" to={`/demo-afspraak`}>Demo inplannen</Link>
                                    <p className="personal-demo-button-text">Geheel vrijblijvend</p>
                                </div>
                                <div className="col-sm-3 previous-demo-container start-demo-container">
                                    <div className="html-text" dangerouslySetInnerHTML={{ __html: personalStartDemoText }} />
                                    <Link className="personal-start-demo-button" key="demo-button" to={`/start-demo`}>Demo inplannen</Link>
                                    <p className="personal-demo-button-text">Ontdek het 'Start' pakket</p>
                                </div>
                                <div className="col-sm-3 previous-demo-container nieuwsgierig-demo-container">
                                    <div className="html-text" dangerouslySetInnerHTML={{ __html: previousDemoText }} />
                                    <Link className="demo-container-button" key="demo-container-button" to={`/demo-terugkijken-aanvragen`}>Demo terugkijken</Link>
                                    <p className="personal-demo-button-text">Ontvang link met onze demo's</p>
                                </div>
                            </span>
                            {/* <div className="demo-buttons-container">
                                <div className="html-text" dangerouslySetInnerHTML={{ __html: demoText }} />
                                <div className="row">
                                    <div className="col-sm-6">
                                        <span>
                                            <Link id="header-demo-button" className="header-links" to="/demo-afspraak">Plan meeting in</Link>
                                        </span>
                                    </div>
                                    <div className="col-sm-6">
                                        <span>
                                            <a id="header-demo-button" className="header-links" href="https://calendly.com/travelspirit-demo/demo">Vraag demo aan</a>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        }
                        {urlParam === 'vvkr-offerte' && <span className="vvkr-offerte">
                            <form id="form1202" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                                <ul className="mpFormTable mpTwoColumnLayout">
                                    <li id="CNT1259" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1259" id="lbl-field1259">Voornaam</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1259" name="field1259"></input>
                                        </div>
                                    </li>
                                    <li id="CNT1260" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1260" id="lbl-field1260">Achternaam</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1260" name="field1260"></input>
                                        </div>
                                    </li>
                                    <li id="CNT1257" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1257" id="lbl-field1257">Bedrijfsnaam</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1257" name="field1257"></input></div>
                                    </li>
                                    <li id="CNT1258" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1258" id="lbl-field1258">VvKR lidmaatschapsnummer</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1258" name="field1258"></input></div>
                                    </li>
                                    <li id="CNT1407" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1407" id="lbl-field1407">Hoeveel medewerkers telt je organisatie?</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField">
                                            <select id="field1407" name="field1407">
                                                <option value="">Selecteer een optie</option>
                                                <option value="Ik ben ZRA of zelfstandig">Ik ben ZRA of zelfstandig</option>
                                                <option value="We zijn met z'n tweeën">We zijn met z'n tweeën</option>
                                                <option value="We werken met 3 personen">We werken met 3 personen</option>
                                                <option value="We zijn met meer dan 3 personen">We zijn met meer dan 3 personen</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li id="CNT1408" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1408" id="lbl-field1408">Wat voor soort reizen bieden jullie aan?</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1408" name="field1408"></input></div>
                                    </li>
                                    <li id="CNT1261" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1261" id="lbl-field1261">Telefoonnummer</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" maxLength="15" size="15" id="field1261" name="field1261"></input></div>
                                    </li>
                                    <li id="CNT1262" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1262" id="lbl-field1262">E-mailadres</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1262" name="field1262"></input></div>
                                    </li>
                                    <li id="CNT1256" role="group" className="mpQuestionTable">
                                        <div className="mpLabelRow">
                                            <div className="submitCellSpacer"><span></span></div>
                                            <div className="submitCell"><input value="Verzenden" className="submitButton" name="next" type="submit" id="field1256"></input></div>
                                            <p className="small-text">Ontvang je offerte binnen 24 uur</p>
                                        </div>
                                    </li>
                                </ul>
                                <fieldset style={{ display: 'none' }}>
                                    <input type="hidden" name="userId" value="2110488"></input>
                                    <input type="hidden" name="formEncId" value="wHWwTrW3ENzmjHW67Lr6"></input>
                                    <input type="hidden" name="pagePosition" value="1"></input>
                                    <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                                    <input type="hidden" name="redir" value="formAdmin2"></input>
                                    <input type="hidden" name="formLayout" value="N"></input>
                                    <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                                </fieldset>
                            </form>
                        </span>
                        }
                        {urlParam === 'start-offerte' && <span className="start-offerte">
                            <form id="form1352" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                                <ul className="mpFormTable mpTwoColumnLayout">
                                    <li id="CNT1409" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1409" id="lbl-field1409">Voornaam</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1409" name="field1409"></input>
                                        </div>
                                    </li>
                                    <li id="CNT1410" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1410" id="lbl-field1410">Achternaam</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1410" name="field1410"></input>
                                        </div>
                                    </li>
                                    <li id="CNT1411" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1411" id="lbl-field1411">Bedrijfsnaam</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1411" name="field1411"></input>
                                        </div>
                                    </li>
                                    <li id="CNT1413" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1413" id="lbl-field1413">Hoeveel medewerkers telt je organisatie?</label>
                                            <span className="mandatorySign">&nbsp;*</span>
                                        </div>
                                        <div className="mpFormField">
                                            <select id="field1413" name="field1413">
                                                <option value="">Selecteer een optie</option>
                                                <option value="Ik ben ZRA of zelfstandig">Ik ben ZRA of zelfstandig</option>
                                                <option value="We zijn met z'n tweeën">We zijn met z'n tweeën</option>
                                                <option value="We werken met 3 personen">We werken met 3 personen</option>
                                                <option value="We zijn met meer dan 3 personen">We zijn met meer dan 3 personen</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li id="CNT1414" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1414" id="lbl-field1414">Wat voor soort reizen bieden jullie aan?</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1414" name="field1414"></input></div>
                                    </li>
                                    <li id="CNT1415" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1415" id="lbl-field1415">Telefoonnummer</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" maxLength="15" size="15" id="field1415" name="field1415"></input></div>
                                    </li>
                                    <li id="CNT1416" role="group" className="mpQuestionTable">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1416" id="lbl-field1416">E-mailadres</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1416" name="field1416"></input></div>
                                    </li>
                                    <li id="CNT1417" role="group" className="mpQuestionTable">
                                        <div className="mpLabelRow">
                                            <div className="submitCellSpacer">
                                                <span></span>
                                            </div>
                                            <div className="submitCell">
                                                <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1417"></input>
                                                <p className="small-text">Ontvang je offerte binnen 24 uur</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <fieldset style={{ display: 'none' }}>
                                    <input type="hidden" name="userId" value="2110488"></input>
                                    <input type="hidden" name="formEncId" value="WKITc9UJyFBLt2pydnKM"></input>
                                    <input type="hidden" name="pagePosition" value="1"></input>
                                    <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                                    <input type="hidden" name="redir" value="formAdmin2"></input>
                                    <input type="hidden" name="formLayout" value="N"></input>
                                    <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                                </fieldset>
                            </form>
                        </span>
                        }
                        {urlParam === 'vtb-zra' && <span className="vtb-offerte">
                            <form id="form1452" enctype="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                                <ul className="mpFormTable mpTwoColumnLayout">
                                    <li id="CNT1557" className="mpQuestionTable">
                                        <div className="mpFormLabel">
                                            <label className="descriptionLabel" htmlFor="field1557" id="lbl-field1557">E-mailadres</label>
                                            <span className="mandatorySign"> *</span>
                                        </div>
                                        <div className="mpFormField">
                                            <input type="text" id="field1557" name="field1557"></input>
                                            <div className="sublabel">naam@bedrijf.nl </div>
                                        </div>
                                    </li>
                                    <li id="CNT1563" className="mpQuestionTable">
                                        <div className="mpLabelRow">
                                            <div className="submitCellSpacer">
                                                <span></span>
                                            </div>
                                            <div className="submitCell">
                                                <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1563"></input>
                                                <p className="small-text">We houden je per email op de hoogte.</p>      
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <fieldset style={{ display: 'none' }}>
                                    <input type="hidden" name="userId" value="2110488"></input>
                                    <input type="hidden" name="formEncId" value="5rDXu5h3J5whZTCrnhZy"></input>
                                    <input type="hidden" name="pagePosition" value="1"></input>
                                    <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                                    <input type="hidden" name="redir" value="formAdmin2"></input>
                                    <input type="hidden" name="formLayout" value="N"></input>
                                    <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                                </fieldset>
                            </form>
                        </span>
                        }
                    </div>
                </div>
            </span>
            {(urlParam === 'demo' || urlParam === 'demo-terugkijken') && <span>
                <div className="demo-form-container">
                    <h2>{demoTitle.value}</h2>
                    <p>{demoSubtitle.value}</p>
                    <form id="form1302" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                        <ul className="mpFormTable mpTwoColumnLayout">
                            <li id="CNT1357" role="group" className="mpQuestionTable ">
                                <div className="mpFormLabel">
                                    <label className="descriptionLabel" htmlFor="field1357" id="lbl-field1357">E-mailadres</label>
                                    <span className="mandatorySign"> *</span>
                                </div>
                                <div className="mpFormField">
                                    <input type="text" id="field1357" name="field1357"></input>
                                    <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                                </div>
                            </li>
                            <li id="CNT1358" role="group" className="mpQuestionTable">
                                <div className="mpLabelRow">
                                    <div className="submitCellSpacer">
                                        <span></span>
                                    </div>
                                    <div className="submitCell demo-form-send-button">
                                        <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1358"></input>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <fieldset style={{ display: 'none' }}>
                            <input type="hidden" name="userId" value="2110488"></input>
                            <input type="hidden" name="formEncId" value="qh592MqkYxFgWarDci26"></input>
                            <input type="hidden" name="pagePosition" value="1"></input>
                            <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                            <input type="hidden" name="redir" value="formAdmin2"></input>
                            <input type="hidden" name="formLayout" value="N"></input>
                            <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                        </fieldset>
                    </form>
                </div>
                {urlParam === 'demo' && <div className="modules-demo-page-container">
                    <DemoButtonContainer url={urlParam} titles={titles} />
                </div>
                }
            </span>
            }
            {urlParam === 'demo-terugkijken-aanvragen' && <span className="demo-terugkijken">
                <form id="form1152" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                    <ul className="mpFormTable mpTwoColumnLayout">
                        <li id="CNT1206" role="group" className="mpQuestionTable">
                            <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1206" id="lbl-field1206">E-mailadres</label><span className="mandatorySign">&nbsp;*</span></div>
                            <div className="mpFormField">
                                <input type="text" id="field1206" name="field1206"></input>
                                <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                            </div>
                        </li>
                        <li id="CNT1207" role="group" className="mpQuestionTable">
                            <div className="mpLabelRow">
                                <div className="submitCellSpacer">
                                    <span></span>
                                </div>
                                <div className="submitCell">
                                    <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1207"></input>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <fieldset style={{ display: 'none' }}>
                        <input type="hidden" name="userId" value="2110488"></input>
                        <input type="hidden" name="formEncId" value="WQXIvJtmYNA4NMe6xGIL"></input>
                        <input type="hidden" name="pagePosition" value="1"></input>
                        <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                        <input type="hidden" name="redir" value="formAdmin2"></input>
                        <input type="hidden" name="formLayout" value="N"></input>
                        <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                    </fieldset>
                </form>
            </span>
            }
            {urlParam === '403' && <div className="form-container page-403">
                <h2>{nieuwsbriefTitle}</h2>
                <p>{nieuwsbriefSubtitle}</p>
                {/* <div id='mpform1102' ></div> */}
                <form id="form1102" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                    <ul className="mpFormTable mpTwoColumnLayout">
                        <li id="CNT1156" className="mpQuestionTable  ">
                            <div className="mpFormLabel">
                                <label className="descriptionLabel" htmlFor="field1156">E-mailadres</label>
                                <span className="mandatorySign"> *</span>
                            </div>
                            <div className="mpFormField">
                                <input type="text" id="field1156" name="field1156"></input>
                                <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                            </div>
                        </li>
                        <li id="CNT1162" className="mpQuestionTable">
                            <div className="mpLabelRow">
                                <div className="submitCellSpacer">
                                    <span></span>
                                </div>
                                <div className="submitCell">
                                    <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1162"></input>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <fieldset style={{ display: 'none' }}>
                        <input type="hidden" name="userId" value="2110488"></input>
                        <input type="hidden" name="formEncId" value="RpPvABh6UMD4wG9y9VHG"></input>
                        <input type="hidden" name="pagePosition" value="1"></input>
                        <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                        <input type="hidden" name="redir" value="formAdmin2"></input>
                        <input type="hidden" name="formLayout" value="N"></input>
                        <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                    </fieldset>
                </form>
            </div>
            }
            {urlParam === '403' && <div className="linkedin-container">
                <h2>{linkedinTitle}<img src={linkedin} alt="linkedin-logo" /></h2>
                <div className="html-text" dangerouslySetInnerHTML={{ __html: linkedinText }} />
                <a id="linkedin-button" className="header-links" href="https://www.linkedin.com/company/travelspirit-software" target="_blank" rel="noreferrer">Naar LinkedIn</a>
            </div>
            }
        </Layout>
    )
}

export default ContentPage

export const pageQuery = graphql`
query ContentPageQuery($id: ID) {
    gcms {
        contentPage(where: { id: $id }) {
            pageTitle
            pageMetaDescription
            pageContent { html }
            slug
            crawlable
        }
        titles {
            indicator
            value
            richValue { html }
        }
    }
    allDataJson {
        edges {
            node {
                vlogs {
                    url
                    title
                }
            }
        }
    }
}`;

    // allDemioData(sort: {fields: timestamp}, filter: {status: {ne: "finished"}}) {
    //     edges {
    //         node {
    //             id
    //             description
    //             name
    //             registration_url
    //             timestamp
    //             zone
    //             labeled_name
    //             status
    //             date_id
    //         }
    //     }
    // }
